
import Media from '@/components/Media'
import Btn from '@/components/Btn'
import HTMLRender from '@/components/HTMLRender'
import utils from '@/mixins/utils'
export default {
  mixins: [utils],
  components: {
    Media,
    HTMLRender,
    Btn,
  },
  data() {
    return {
      edjsParser: null,
    }
  },

  props: {
    data: {
      type: Object,
      default: () => {
        return null
      },
    },
  },
}
